<template>
  <div>Signing-in&hellip;</div>
</template>

<script>
export default {
  computed: {
    params () {
      return new URLSearchParams(document.location.hash.substr(1))
    }
  },
  mounted () {
    const id_token = this.params.get('id_token')
    const access_token = this.params.get('access_token')
    this.$store.commit('login', { id_token, access_token })
    this.$router.push({ name: 'Home' })
  }
}
</script>